import React, { Component, useRef } from "react";
import { memo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  getAllMessageByOrg,
  acceptTaskAction,
  acceptGoalAction,
  approveTaskAction,
  rejectedTaskAction,
  rejectGoalAction,
  rejectTaskAction,
  readMessages,
} from "../../services/messageService";
import profilePicture from "../../images/profile/profilePic.png";
import { Row, Modal, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../components/Menu/Sidebar";
import LoadingSpinner from "../components/Component/LoadingSpinner";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addingComment } from "../../services/messageService";

const CommentSchema = Yup.object().shape({
  comment: Yup.string().required('Please write something here')
});

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessage: false,
      PrevMessage: [],
      UnreadMessageIds: [],
      OpenModal: false,
      loadMore: false,
      OpenRejectModal: false,
      SelectedMessage: "",
      SelectedRejectMessage: "",
      rejectTaskForm: {
        reject_reason: "",
        reject_description: "",
      },
      rejectedTaskForm: {
        rejected_reason: "",
        rejected_description: "",
      },
      isInfo: false,
      toggle: false,
      index: "",
    };
    this.roomId = props?.props?.user?.room_id;
    this.listRef = (useRef < HTMLDivElement) | (null > null);
    this.userData = JSON.parse(localStorage?.getItem("userDetails"))?.data;
    this.token = JSON.parse(
      localStorage?.getItem("userDetails")
    )?.data?.accessToken;
    this.orgId = localStorage?.getItem("organization_id");
  }


  handleToggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  handleSubmit = (values, { setSubmitting, resetForm }, oneMessage) => {
    // Handle form submission
    console.log(values?.comment,oneMessage?.id,"valuesbakadaddada");
    const data = addingComment( oneMessage?.id,   
      JSON.parse(localStorage?.getItem("userDetails"))?.data?.accessToken,
      values?.comment
    );
    data?.then(async (res) => {
      console.log(res, "pppresponse")
      this.getMessagesByOrg()
    })
    data?.catch(async (err) => {
      console.log(err, "pppresponse")
    })

    resetForm()
    setSubmitting(false);
  };

  componentDidMount() {
    this.requestNotificationPermission();
    this.connectToNotificationWebSocket();
    this.getMessagesByOrg();
    this.setState({ PrevMessage: [] });
  }

  componentDidUpdate(e) {
    this.scrollToBottom();
  }

  scrollToBottom = (e) => {
    const element = document.getElementById("chartBox");
    element.scrollTop = element.scrollHeight;
  };

  changedDateFormat = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var today1 = new Date(date);
    return (
      days[today1.getDay()] +
      ",  " +
      monthNames[today1.getMonth()] +
      " " +
      String(today1.getDate()).padStart(2, "0") +
      "th, " +
      " " +
      today1.getFullYear()
    );
  };

  getTwentyFourHourTime = (date) => {
    var d = new Date(date);
    return d.getHours() + ":" + d.getMinutes();
  };

  getTimeZone = (date) => {
    var today1 = new Date(date);
    let dates = today1.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return this.getTwentyFourHourTime(dates);
  };

  getMessagesByOrg(start = 0, end = 10) {
    var UnreadMessageIds = [];
    const data = getAllMessageByOrg(
      localStorage?.getItem("organization_id"),
      start,
      end,
      JSON.parse(localStorage?.getItem("userDetails"))?.data?.accessToken
    );
    data?.then(async (res) => {
      console.log(res?.data , "Responses data")
      if (res.data.message === "Messages found successfully") {
        let data = this.state.PrevMessage;
        if (res?.data?.data?.data.length === 10) {
          this.setState({ loadMore: true });
        } else {
          this.setState({ loadMore: false });
        }
        this.setState({ isMessage: true });
        res?.data?.data?.data?.forEach((element) => {
          data.push(element);
          if (element?.message_read === false) {
            UnreadMessageIds.push(element?.id);
          }
        });
        this.setState({
          PrevMessage: [
            ...data
              .reduce((map, obj) => map.set(obj.id, obj), new Map())
              .values(),
          ]?.sort((a, b) => a?.id - b?.id),
        });
        this.setState({ UnreadMessageIds: UnreadMessageIds });
        setTimeout(() => {
          if (UnreadMessageIds?.length) {
            const data = readMessages(
              this.state?.UnreadMessageIds,
              JSON.parse(localStorage?.getItem("userDetails")).data
            );
            data?.then(async (res) => {
              this.props.ChatUpdate("update");
              this.getMessagesByOrg();
            });
            data?.catch(async (err) => {
              console.log("error user org list");
            });
          }
        }, 1000);
      
      }
    });
    data?.catch(async (err) => {});
  }

  requestNotificationPermission = () => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted');
        } else {
          console.log('Notification permission denied');
        }
      });
    }
  };

  handleNewMessageNotification = (msg) => {
    if (Notification.permission === 'granted' && msg.sender_id !== this.userData.id) {
      const title = `New message from ${msg.sender_name}`;
      const body = msg.message;
      new Notification(title, { body });
    }
  };

  render() {
    return (
      <>
        <div>
          <Row style={{}} data-testid="Noti-render">
            <Col className={this.state.toggle ? "col-sm-6" : "col-sm-12"}>
              {this.state.loadMore && !this.state.noLoading && (
                <Row className="d-flex justify-content-end m-2">
                  <div className="" style={{ textAlign: "center" }}>
                    <Link
                      to=""
                      className="figma-link col-sm-3 mx-5"
                      data-bs-toggle="tooltip"
                      title="Load more messages"
                      onClick={(e) => {
                        e.preventDefault();
                        this.getMessagesByOrg(
                          this.state?.PrevMessage?.length,
                          this.state?.PrevMessage?.length + 10
                        );
                      }}
                    >
                      {" "}
                      Load more...
                    </Link>
                  </div>
                </Row>
              )}
              <PerfectScrollbar
                className="dlab-scroll"
                id="chartBox"
                style={{ height: "57vh" }}
              >
                {this.state.PrevMessage?.length !== 0 ? (
                  <>
                    <div className="chat-window mx-1 my-1">
                      <div className="chat-box-area chat-box-area">
                        <div
                          className=" dz-scroll"
                          id="ChatWindowInner"
                          ref={this.listRef}
                        >
                          {Array?.isArray(this.state?.PrevMessage) &&
                            this.state?.PrevMessage?.map((oneMessage, index) =>
                              
                              oneMessage?.message_type === "completed" ? (
                                <div
                                  className="media justify-content-right align-items-start "
                                  key={index}
                                  ref={(el) => {
                                    this.el = el;
                                  }}
                                  
                                >
                                  {oneMessage?.message_body && (
                                    // <>
                                    //   <div className="chat-img m-3">
                                        // <img
                                        //   src={
                                        //     oneMessage?.sender
                                        //       ?.profile_picture ||
                                        //     profilePicture
                                        //   }
                                        //   alt="message-body"
                                        //   title="message-body"

                                        //   className="rounded-circle img-1"
                                        // />
                                    //     {oneMessage?.sender?.logged_in ? (
                                    //       <span
                                    //         className="active"
                                    //         style={{ bottom: "-1px" }}
                                    //       ></span>
                                    //     ) : (
                                    //       <span
                                    //         className="inActive"
                                    //         style={{ bottom: "-1px" }}
                                    //       ></span>
                                    //     )}
                                    //   </div>
                                    //   <div className="message-sentNoti dropdown1 mt-3">
                                    //     <div className="d-flex justify-content-between mb-0 " >
                                    //       <p
                                    //         className="message mb-1"
                                    //         key={index}
                                    //         style={{
                                    //           minWidth: "450px",
                                    //           cursor: "auto",
                                    //           backgroundColor: oneMessage.message_read ? "":"#b7b9b9"
                                    //         }}
                                            
                                    //       >
                                    //         <Row>
                                    //           <span className="p">
                                    //             {oneMessage?.message_body}
                                    //           </span>
                                    //         </Row>
                                    //       </p>
                                    //     </div>
                                    //     <span className="filterText">
                                    //       {oneMessage?.sender?.first_name +
                                    //         " " +
                                    //         oneMessage?.sender?.last_name +
                                    //         " " +
                                    //         this.changedDateFormat(
                                    //           oneMessage?.created_at
                                    //         ) +
                                    //         " " +
                                    //         this.changedDateFormat(
                                    //           oneMessage?.created_at
                                    //         ) +
                                    //         " " +
                                    //         this.getTimeZone(
                                    //           oneMessage?.created_at
                                    //         )}
                                    //     </span>
                                    //   </div>
                                    // </>




                                    <>
                                    <div
                                      className="media justify-content-right align-items-start"
                                      key={index}
                                    >
                                      <>
                                        <div className="chat-img m-3">
                                          <img
                                            src={
                                              oneMessage?.sender
                                                ?.profile_picture ||
                                              profilePicture
                                            }
                                            alt="profile-pic"
                                            title="profile-pic"
  
                                            className="rounded-circle img-1 "
                                          />
                                          {oneMessage?.sender?.logged_in ? (
                                            <span
                                              className="active"
                                              style={{ bottom: "-1px" }}
                                            ></span>
                                          ) : (
                                            <span
                                              className="inActive"
                                              style={{ bottom: "-1px" }}
                                            ></span>
                                          )}
                                        </div>
                                        <div className="mt-3">
                                          <span className="p" style={{wordBreak: 'break-word'}}>
                                            {oneMessage?.sender?.first_name +
                                              " " +
                                              oneMessage?.sender?.last_name +
                                              " "}
                                          </span>
                                          <span className="filterText fs-14">
                                            {this.changedDateFormat(
                                              oneMessage?.created_at
                                            ) +
                                              " " +
                                              this.getTimeZone(
                                                oneMessage?.created_at
                                              )}
                                          </span>
                                          <div className="message-sentNoti dropdown1" >
                                            <div className="d-flex justify-content-between mb-0 ">
                                              {/* <p
                                                className="mb-1 message plansom-notification"
                                                key={index}
                                                style={{
                                                  minWidth: "450px",
                                                  cursor: "auto",
                                                  backgroundColor: oneMessage?.message_read ? "":"#b7b9b9"
                                                }}
                                              >
                                                <Row className="d-flex justify-content-between">
                                                  <span className="p">
                                                    {oneMessage?.message_body}
                                                  </span>
                                                </Row>
  
                                                {oneMessage?.message_type !==
                                                  "commented" && (oneMessage?.task || oneMessage?.goal) && (
                                                  <div className="row d-flex">
                                                    <div className="text-start p fs-14">
                                                      Check it out
                                                      <Link
                                                        to=""
                                                        className="figma-link sharp text-center"
                                                        onClick={(event) => {
                                                          this.setState(() => ({
                                                            index: index,
                                                          }));
                                                          this.setState(() => ({
                                                            toggle: true,
                                                          }));
                                                          this.setState(() => ({
                                                            SelectedMessage:
                                                              oneMessage,
                                                          }));
                                                          event.preventDefault();
                                                        }}
                                                      >
                                                        here ...
                                                      </Link>
                                                    </div>
                                                  </div>
                                                )}
                                                <Row>
                                                     
                 <Formik
          initialValues={{ comment: '' }}
          validationSchema={CommentSchema}
          onSubmit={this.handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              
              <div className="input-group mt-3">
                <Field type="text" name="comment" className="form-control" placeholder="Add a comment" style={{height:"35px"}}/>
                <ErrorMessage name="comment" component="div" className="text-danger" />
                <button type="submit" className="btn btn-primary" >
                  Comment
                </button>
              </div>
             
            </Form>
          )}
        </Formik>
                                                </Row>
                                                </p> */}


                     <p
                  className="mb-1 message plansom-notification"
                  key={index}
                  style={{
                    minWidth: '450px',
                    cursor: 'auto',
                    backgroundColor: oneMessage?.message_read ? '' : '#b7b9b9',
                  }}
                >
                  <Row className="d-flex justify-content-between">
                    <span className="p">{oneMessage?.message_body}</span>
                  </Row>

                  {oneMessage?.message_type !== 'commented' && (oneMessage?.task || oneMessage?.goal) && (
                    <div className="row d-flex">
                      <div className="text-start p fs-14">
                        Check it out
                        <Link
                          to=""
                          className="figma-link sharp text-center"
                          onClick={(event) => {
                            this.setState(() => ({ index: index }));
                            this.setState(() => ({ toggle: true }));
                            this.setState(() => ({ SelectedMessage: oneMessage }));
                            event.preventDefault();
                          }}
                        >
                          here ...
                        </Link>
                      </div>
                    </div>
                  )}
                { oneMessage?.comment.map((item, index) =>
                 <div key={index} className="pt-2">
                  <Row>
                    <Col xs={3} sm={2} lg={1}>
                  <img
                                          src={
                                            item?.user
                                              ?.profile_picture ||
                                            profilePicture
                                          }
                                          alt="message-body"
                                          title="message-body"

                                          style={{height:"35px", width:"35px"}}

                                          className="rounded-circle img-1"
                                        />

</Col>          
<Col xs={9} sm={10} lg={11}>                      
                                        <div >
                                        <div className="fs-13">{item?.user?.first_name}<span style={{paddingLeft:"1%", wordBreak: 'break-word'}}>{item?.user?.last_name}</span></div>
                                        <div  style={{wordBreak: 'break-word'}}><span style={{fontWeight:"500"}}> {item?.feedback}</span></div>
                                        </div>
                                        </Col> 
                                      
                  </Row>
                  </div>)}

                  <Row>
                    <Formik
                      initialValues={{ comment: '' }}
                      validationSchema={CommentSchema}
                      onSubmit={(values, formikBag) => this.handleSubmit(values, formikBag, oneMessage)}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div className="input-group mt-3">
                            <Field
                              type="text"
                              name="comment"
                              className="form-control"
                              placeholder="Add a comment"
                              style={{ height: '40px' }}
                            />
                            <button
    className="send-btn float-end mx-2 my-1"
    style={{ width: "9%", borderRadius:"100%" }}
    onClick={() => {
     
    }}
  >
    <i className="far fa-paper-plane me-1"></i>
   
  </button>
                          </div>
                          <ErrorMessage name="comment" component="div" className="text-danger" />
                        </Form>
                      )}
                    </Formik>
                  </Row>
                </p>
                                          
                                                
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </>
                                  




                                  )}
                                </div>
                              ) : oneMessage?.message_type === "assigned" ||
                                oneMessage?.message_type === "approved" ||
                                oneMessage?.message_type === "rejected" ||
                                oneMessage?.message_type === "accepted" ||
                                oneMessage?.message_type === "uploaded" ||
                                oneMessage?.message_type === "rejected" 
                                 ? (
                                <>
                                  <div
                                    className="media justify-content-right align-items-start"
                                    key={index}
                                  >
                                    <>
                                      <div className="chat-img m-3">
                                        <img
                                          src={
                                            oneMessage?.sender
                                              ?.profile_picture ||
                                            profilePicture
                                          }
                                          alt="profile-pic"
                                          title="profile-pic"

                                          className="rounded-circle img-1 "
                                        />
                                        {oneMessage?.sender?.logged_in ? (
                                          <span
                                            className="active"
                                            style={{ bottom: "-1px" }}
                                          ></span>
                                        ) : (
                                          <span
                                            className="inActive"
                                            style={{ bottom: "-1px" }}
                                          ></span>
                                        )}
                                      </div>
                                      <div className="mt-3">
                                        <span className="p">
                                          {oneMessage?.sender?.first_name +
                                            " " +
                                            oneMessage?.sender?.last_name +
                                            " "}
                                        </span>
                                        <span className="filterText fs-14">
                                          {this.changedDateFormat(
                                            oneMessage?.created_at
                                          ) +
                                            " " +
                                            this.getTimeZone(
                                              oneMessage?.created_at
                                            )}
                                        </span>
                                        <div className="message-sentNoti dropdown1" >
                                          <div className="d-flex justify-content-between mb-0 ">
                                            <p
                                              className="mb-1 message plansom-notification"
                                              key={index}
                                              style={{
                                                minWidth: "450px",
                                                cursor: "auto",
                                                backgroundColor: oneMessage?.message_read ? "":"#b7b9b9"
                                              }}
                                            >
                                              <Row className="d-flex justify-content-between">
                                                <span className="p">
                                                  {oneMessage?.message_body}
                                                </span>
                                              </Row>

                                              {oneMessage?.message_type !==
                                                "commented" && (oneMessage?.task || oneMessage?.goal) && (
                                                <div className="row d-flex">
                                                  <div className="text-start p fs-14">
                                                    Check it out
                                                    <Link
                                                      to=""
                                                      className="figma-link sharp text-center"
                                                      onClick={(event) => {
                                                        this.setState(() => ({
                                                          index: index,
                                                        }));
                                                        this.setState(() => ({
                                                          toggle: true,
                                                        }));
                                                        this.setState(() => ({
                                                          SelectedMessage:
                                                            oneMessage,
                                                        }));
                                                        event.preventDefault();
                                                      }}
                                                    >
                                                      here ...
                                                    </Link>
                                                  </div>
                                                </div>
                                              )}
                                              </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </>
                              ) : (
                                ""
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : this.state.isMessage &&
                  this.state?.PrevMessage?.length === 0 ? (
                  <>
                    <center>
                      <div style={{ padding: "20px", margin: "120px" }}></div>
                      <span className="p">No message available .</span>
                    </center>
                  </>
                ) : (
                  <>
                    <LoadingSpinner/>
                  </>
                )}
              </PerfectScrollbar>
            </Col>
            <Col className="col-sm-6 d-none d-sm-block">
              <div className="m-0" data-testid="message" >
                {this.state?.SelectedMessage &&
                  this.state?.toggle &&
                  this.state?.index === this.state?.index && (
                    <Sidebar
                      data={this.state?.SelectedMessage}
                      close={() => {
                        this.setState({ toggle: false });
                      }}
                      refresh={() =>
                        setTimeout(() => {
                          this.getMessagesByOrg();
                        }, 1000)
                      }
                      noti={true}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default memo(Notification);
