import React, { useState, useEffect, useRef, lazy } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import profilePicture from "../../../images/profile/profilePic.png";
import { Dropdown, Col, Row } from "react-bootstrap";
import LogoutPage from "./Logout";
import {
  getUserSpecificOrgList,
  setDefaultOrgAction,
} from "../../../store/actions/OrganizationActions";
import { getAllTeams } from "../../../store/actions/TeamAction";
import { getOrgById } from "../../../services/OrganizationService";
import PlansomLogo from "../../../images/vector/Frame.png";
import PlansomLogo2 from "../../../images/PlansomLogo2.png";
import { Button, Modal } from "react-bootstrap";
import {
  logout,
  loginConfirmedAction,
} from "../../../store/actions/AuthActions";
import {
  sendAccessToken,
  saveTokenInLocalStorage,
  sendUserSuggestion
} from "../../../services/AuthService";
import SearchModal from "./SearchModal";
import { uploadTaskAndGoalFile } from "../../../services/TaskService";
import {
  fileUploadConfirmAction,
  readNotificationConfirmAction,
} from "../../../store/actions/NotificationAction";
import ReactSearchBox from "react-search-box";
import { getPlanExpireNotification } from "../../../services/StripePayment";
import SubscriptionPlans from "../../components/Menu/SubscriptionPlans";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/Component/LoadingSpinner";
import RatingAndComment from "../StoreFront/RatingAndComment";
import { Popover, Popper } from "@material-ui/core";
import { Stack } from "@mui/material";
import TaskModal from "../../components/Component/CreateTaskModal";
import config from "../../../services/config";
import MessageSeconNotification2 from "../../../sound/MessageSeconNotification2.mp3"

const notification_Url = config.notificationUrl;

const GoalModal = lazy(() => import("../../../jsx/components/Component/GoalModal"));

const CustomTooltip = ({ show, content, targetRef }) => {
  if (!show) {
    return null;
  }
  let targetRect = targetRef?.current?.getBoundingClientRect();
  let tooltipTop = targetRect?.bottom + 5; // Adjust the vertical position
  let tooltipLeft = targetRect?.left - 40; // Center horizontally
  return (
    <div
      className="fade show tooltip bs-tooltip-top"
      style={{
        position: "absolute",
        top: tooltipTop,
        left: tooltipLeft,
        padding: "5px",
        backgroundColor: "#211f1f",
        width: "200px",
      }}
    >
      <div
        className="tooltip-pointer"
        style={{
          position: "absolute",
          width: "0px",
          height: "0px",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid transparent",
          borderBottom: "8px solid #333",
          top: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      ></div>
      {content}
    </div>
  );
};

const Header = () => {
  let fileRef = useRef();
  let dropdownToggleRef = useRef(null);
  let tooltipRef = useRef(null);
  let location = useLocation();
  const target = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [teamList, getTeamDetails] = useState([]);
  const stateData = useSelector((state) => state);
  const [userOrgDetails, getUserOrgList] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedScope, setSelectedScope] = useState("Organization");
  const [selectedScopeUpload, setSelectedScopeUpload] =
    useState("organization");
  const [selectedTimeScope, setSelectedTimeScope] = useState("1 month");
  const [customDateModal, getCustomDateModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(
    localStorage.getItem("team_name")
  );
  const [selectedTeamUpload, setSelectedTeamUpload] = useState(
    localStorage.getItem("team_name")
  );
  const [selectedTeamUploadName, setSelectedTeamUploadName] = useState(
    localStorage.getItem("team_name")
  );

  const [orgDetails, setOrgDetails] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [FileErrorMessage, setFileErrorMessage] = useState("");
  const [scrollbar, setScrollBar] = useState(false);
  const [showTeamDropdown, setTeamListDropdown] = useState(false);
  const [showTeamDropdownUpload, setTeamListDropdownUpload] = useState(false);
  const orgId = localStorage.getItem("organization_id");
  const localScope = localStorage.getItem("scope");
  let date = new Date();
  let month = date.getMonth();
  const [startDate, setStartDate] = useState(date.setMonth(month - 1));
  const [endDate, setEndDate] = useState(new Date());
  const [profilePic, setProfilePicture] = useState("");
  const [FileStatus, setFileStatus] = useState(false);
  const [FileStatusError, setFileStatusError] = useState(false);
  const [FileStatusErrorMessage, setFileStatusErrorMessage] = useState();
  const [toggleSideBar, setToggle] = useState(true);
  const [ShowTemplateLoader, setShowTemplateLoader] = useState(false);
  const [teamMem, setTeamMem] = useState("");
  const [member, setMember] = useState("");
  const [memberName, setMemberName] = useState("");
  const [openSendQuery, setOpenSendQuery] = useState(false);
  const [suggestionText, setSuggestionText] = useState("");
  const [submittingSuggestion, setSubmittingSuggestion] = useState(false);

  const [notificationSocket, setNotificationSocket] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleDropdownFilterClick = () => {
    setShowModal(!showModal);
  };

  const scope = [
    {
      name: "Organization",
      value: "organization",
    },
    {
      name: "Team",
      value: "team",
    },
    {
      name: "Personal",
      value: "personal",
    },
  ];

  const timeScope = [
    {
      name: "1 day",
      value: "1 day",
    },
    {
      name: "7 days",
      value: "7 days",
    },
    {
      name: "14 days",
      value: "14 days",
    },
    {
      name: "1 month",
      value: "1 month",
    },
    {
      name: "Custom",
      value: "custom",
    },
  ];
  const scopeForUploadStrategy = [
    {
      name: "Organization",
      value: "organization",
    },
    {
      name: "Team",
      value: "team",
    },
    {
      name: "Personal",
      value: "personal",
    },
  ];

  const [tooltip, setTooltip] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalClick, setModalClick] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [trialModalOpen, setTrialModalOpen] = useState(false);
  const [showTrialMessage, setShowTrialmessage] = useState(true);
  const [strategyImported, setStrategyImported] = useState(
    sessionStorage.getItem("strategyImported")
  );
  const [isDropdownReady, setIsDropdownReady] = useState(false);
  const [strategyStatus, setStrategyStatus] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [teamMemberListDropdown, setTeamMemberListDropdown] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopOver, setShowPopOver] = useState(false);
  const [ShowGoalModal, setShowGoalModal] = useState(false);
  const [Shows, setShows] = useState(false);
  const [CreateTask, setCreateTask] = useState(false);

  const handleClose = () => {
    setShowGoalModal(false);
  };

  const handleCreatePopOver = () => {
    setShowPopOver(false);
    setAnchorEl(null);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showTeamDropdown) {
      setTimeout(() => {
        setTooltip(true);
        setIsDropdownReady(true);
      }, 1000);
      // tooltipRef.current.focus();
    }
  }, [showTeamDropdown]);

  useEffect(() => {
    if (modal) {
      setModal(modal);
      setModalClick(modalClick);
    }
  }, [modal, modalClick]);

  useEffect(() => {
    getTeamDetails(stateData?.team?.team?.data);
  }, [stateData?.team?.team]);

  useEffect(() => { }, [strategyImported]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFileName(file.name);
    }
  };

  useEffect(() => {
    setSelectedScopeUpload(selectedScopeUpload);
    if (selectedScopeUpload === "Team") {
      setMember(member);
      setMemberName(memberName);
    } else {
      setMember("");
    }
  }, [selectedScopeUpload]);

  useEffect(() => {
    setSelectedScope(selectedScope);

    if (selectedScope.name === "Team") {
      setTooltip(true);
    } else {
      setTooltip(false);
      setIsDropdownReady(false);
    }
  }, [selectedScope, localScope, localStorage.getItem("scope")]);

  useEffect(() => {
    setSelectedTeam(selectedTeam);
  }, [selectedTeam]);

  useEffect(() => {
    setSelectedTeamUpload(selectedTeamUpload);
  }, [selectedTeamUpload]);

  useEffect(() => {
    setTimeout(() => {
      setFileStatus(false);
    }, 3500);
  }, [FileStatus]);

  useEffect(() => {
    setTimeout(() => {
      setFileStatusError(false);
    }, 3500);
  }, [FileStatusError]);

  useEffect(() => {
    setTimeout(() => {
      setFileErrorMessage("");
    }, 2000);
  }, [FileErrorMessage]);

  useEffect(() => {
    if (!searchModalOpen) {
      setSearchKey(() => "");
    }
  }, [searchModalOpen]);

  useEffect(() => {
    setSelectedItem(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    setSelectedTimeScope(selectedTimeScope);
  }, [selectedTimeScope]);

  // useEffect(() => {
  //   setSelectedItem(selectedItem);
  // }, [selectedItem]);


  const storeOrg = (Org) => {
    localStorage.setItem("organization_id", Org.organization);
    setSelectedItem(Org.organization_name);
    dispatch(
      setDefaultOrgAction(Org.organization, userInfoDetails.data.accessToken)
    );
  };
  const storeMember = (member) => {
    setMember(member?.id);
    setMemberName(member?.first_name);
  };

  const storeScope = (scope) => {
    localStorage.setItem("scope", scope.value);
    setSelectedScope(scope.name);

    if (scope.value === "team") {
      setTeamListDropdown(true);
    } else {
      localStorage.removeItem("team_id");
      localStorage.removeItem("team_name");
      navigate(location?.pathname, { state: { refresh: true } });
      setTeamListDropdown(false);
      setTeamMemberListDropdown(false);
    }
    navigate(location?.pathname, { state: { refresh: true } });
  };

  const storeScopeUpload = (scope) => {
    setSelectedScopeUpload(scope.name);
    if (scope.value === "team") {
      setTeamListDropdownUpload(true);
    } else {
      navigate(location?.pathname, { state: { refresh: true } });
      setTeamListDropdownUpload(false);
      setTeamMemberListDropdown(false);
    }
    navigate(location?.pathname, { state: { refresh: true } });
  };

  const storeTimeScope = (timescope) => {
    setSelectedTimeScope(timescope.name);
    if (timescope.value === "custom") {
      getCustomDateModal(true);
      let date = new Date();
      let month = date.getMonth();
      setStartDate(date.setMonth(month - 1));
      setEndDate(new Date());
      localStorage.setItem("customDatesInUse", "true");
      // formatDate(startDate, "start");
      // formatDate(endDate, "end");
    } else {
      getCustomDateModal(false);
      localStorage.setItem("timescope", timescope.value);
      localStorage.removeItem("startDate");
      localStorage.removeItem("endDate");
      navigate(location?.pathname, { state: { refresh: true } });
    }
  };

  useEffect(() => {
    const customDatesInUse = localStorage.getItem("customDatesInUse");
    if (customDatesInUse === "true") {
      const storedStartDate = localStorage.getItem("startDate");
      const storedEndDate = localStorage.getItem("endDate");
      setStartDate(new Date(storedStartDate));
      setEndDate(new Date(storedEndDate));
    }
  }, []);

  async function getOrgByOrgId(orgId, token) {
    if (orgId !== null) {
      const data = getOrgById(orgId, token);
      data?.then((res) => {
        setOrgDetails(res?.data?.data);
        if (res?.data?.data?.strategy?.length != 0) {
          setStrategyStatus(true);
          localStorage.setItem("strategyStatus", true);
        }
      });
      data?.catch((err) => {
        console.log("error for free trial", err?.response);

        if (
          err?.response?.status === 403 &&
          err?.response?.data?.message ===
          "Your free trial has been ended, Please buy a subscription to continue"
        ) {
          setTrialModalOpen(true);
          setShowTrialmessage(
            err?.response?.data?.data?.subscription_status === "free"
              ? true
              : false
          );
        }
        if (
          err?.response?.status === 403 &&
          err?.response?.data?.message ===
          "Your subscription has been expired, Please renew to continue"
        ) {
          setTrialModalOpen(true);
          setShowTrialmessage(
            err?.response?.data?.data?.subscription_status === "free"
              ? true
              : false
          );
        }
        if (
          err?.response?.status === 403 &&
          err?.response?.data?.message ===
          "Your licence has been expired, Please contact admin team"
        ) {
          setTrialModalOpen(true);
          setShowTrialmessage(
            err?.response?.data?.data?.subscription_status === "free"
              ? true
              : false
          );
        }
      });
    }
  }

  function setScopeValue() {
    const teamName = localStorage.getItem("team_name") || "";
    if (localScope === "organization") {
      setSelectedScope("Organization");
      // setSelectedScopeUpload("Organization");
      setSelectedTeam();
      localStorage.removeItem("team_id");
      localStorage.removeItem("team_name");
      setTeamListDropdown(false);
    } else if (localScope === "personal") {
      setSelectedScope("Personal");
      // setSelectedScopeUpload("Personal");
      setSelectedTeam();
      localStorage.removeItem("team_id");
      localStorage.removeItem("team_name");
      setTeamListDropdown(false);
    } else if (localScope === "team") {
      setSelectedScope("Team");
      // setSelectedScopeUpload("Team");
      setTeamListDropdown(true);
    }
  }

  const storeTeam = (team) => {
    setSelectedTeam(team.name);
    // setTeamMemberListDropdown(true);
    localStorage.setItem("team_id", team.id);
    localStorage.setItem("team_name", team.name);
    navigate(location?.pathname, { state: { refresh: true } });
    // teamList.map((t) => {
    //   if (team.name === t.name) {
    //     setTeamMem(t.team_members);
    //   }
    // });
  };

  const storeTeamUpload = (team) => {
    setSelectedTeamUpload(team.id);
    setSelectedTeamUploadName(team.name);
    // setTeamMemberListDropdown(true);

    navigate(location?.pathname, { state: { refresh: true } });
    teamList.map((t) => {
      if (team.name === t.name) {
        setTeamMem(t.team_members);
      }
    });
  };

  useEffect(() => {
    async function getdata() {
      getOrgByOrgId(orgId, userInfoDetails.data.accessToken);
      const TScope = localStorage.getItem("timescope");
      setSelectedTimeScope(TScope);
      setScopeValue();
      await getUserSpecificOrg();
      await getAlTeams();
      if (location?.state?.toggleSideBar === true) {
        setToggle(false);
      }
    }
    getdata();
  }, []);

  useEffect(() => {
    formatDate(startDate, "start");
    formatDate(endDate, "end");
  }, [startDate, endDate]);

  const data = JSON.parse(localStorage.getItem("userDetails"))?.data
    ?.profile_picture;
  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userDetails"));
    if (userInfo?.data?.profile_picture === null) {
      setProfilePicture(profilePicture);
    } else {
      let profile = userInfo?.data?.profile_picture;
      setProfilePicture(profile);
    }
  }, [
    userInfoDetails,
    data,
    JSON.parse(userDetails),
    stateData.auth?.userDetails,
  ]);

  useEffect(() => {
    async function getdatas() {
      if (stateData.org?.successMessage === "User Privileges Found") {
        if (stateData.org?.auth?.organizations?.length === 1) {
          getUserOrgList(stateData.org.orgDetails);
        }
      } else if (
        stateData.org?.orgDetails === "Organization deleted successfully"
      ) {
        getUserOrgList();
        getUserSpecificOrg();
        dispatch(readNotificationConfirmAction([]));
        setOrgDetails();
        setSelectedItem(null);
        localStorage.removeItem("organization_id");
      }
      if (
        stateData.org?.successMessage ===
        "Default Organization set successfully"
      ) {
        // Add conditions for opening  buy subscription plan modal

        const data = getUserSpecificOrg();
        data?.then((res) => {
          console.log("data in header", res);
        });
        data?.catch((err) => {
          if (
            err?.response?.status === 403 &&
            err?.response?.data?.message ===
            "Your free trial has been ended, Please buy a subscription to continue"
          ) {
            setTrialModalOpen(true);
            setShowTrialmessage(
              err?.response?.data?.data?.subscription_status === "free"
                ? true
                : false
            );
          }
          if (
            err?.response?.status === 403 &&
            err?.response?.data?.message ===
            "Your subscription has been expired, Please renew to continue"
          ) {
            setTrialModalOpen(true);
            setShowTrialmessage(
              err?.response?.data?.data?.subscription_status === "free"
                ? true
                : false
            );
          }
          if (
            err?.response?.status === 403 &&
            err?.response?.data?.message ===
            "Your licence has been expired, Please contact admin team"
          ) {
            setTrialModalOpen(true);
            setShowTrialmessage(
              err?.response?.data?.data?.subscription_status === "free"
                ? true
                : false
            );
          }
        });
        await getAlTeams();
        await getOrgByOrgId(orgId, userInfoDetails.data.accessToken);
        navigate(location?.pathname, { state: { refresh: true } });
      }
      if (
        stateData.org?.orgDetails?.message ===
        "Organization created successfully"
      ) {
        await getUserSpecificOrg();
      }
      if (stateData.org.baseOrg?.org_name !== undefined) {
        setSelectedItem(stateData.org.baseOrg?.org_name);
        await getOrgByOrgId(
          stateData.org.baseOrg?.organization_id,
          userInfoDetails.data.accessToken
        );
        localStorage.setItem(
          "organization_id",
          stateData.org.baseOrg?.organization_id
        );
      }
      if (stateData.team?.team?.message === "Teams found successfully") {
        getTeamDetails(stateData?.team?.team?.data);
      }
    }
    getdatas();
  }, [stateData.auth, stateData.team, stateData.org]);

  const handle = () => {
    setToggle(!toggleSideBar);
    navigate(location?.pathname, { state: { toggleSideBar: toggleSideBar } });
  };

  function formatDate(date, key) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const formatedDate = [year, month, day].join("-");
    if (key === "start") {
      localStorage.setItem("startDate", formatedDate);
    } else {
      localStorage.setItem("endDate", formatedDate);
    }
  }

  function submitStartEndDate() {
    localStorage.setItem("timescope", "custom");
    localStorage.setItem("noOfDays", "5 days");

    getCustomDateModal(false);
    navigate(location?.pathname, { state: { refresh: false } });
  }

  async function submitSuggestion(description) {
    try {
      if (suggestionText !== "") {
        setSubmittingSuggestion(true);
        const response = await sendUserSuggestion(userInfoDetails.data.id, description, userInfoDetails.data.accessToken);
        setOpenSendQuery(false);
        setSubmittingSuggestion(false);
        setSuggestionText("");
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h4 style={{ fontSize: "18px" }}>
                  {response?.data?.message}
                </h4>
              </div>
            </div>
          </>,
          {
            toastId: "upload-suggestion",
            className: "toast-success-container toast-success-container-after",
          }
        );
      }
    } catch (error) {
      toast(
        <>
          <div className="m-1 d-flex">
            <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
            <div>
              <h2 style={{
                fontSize: "22px"
              }}>
                Failed
              </h2>
              <h3 style={{
                fontSize: "18px"
              }}>
                {error?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "upload-suggestion",
          className: 'toast-error-container toast-error-container-after ',
        }
      )
    }
  }


  function cancelCustom() {
    getCustomDateModal(false);
  }

  function CloseSendQueryModel() {
    setOpenSendQuery(false);
    setSuggestionText("");
  }

  function OpenSendQueryModel() {
    setOpenSendQuery(true);
  }

  async function getUserSpecificOrg() {
    dispatch(
      getUserSpecificOrgList(
        userInfoDetails.data.id,
        userInfoDetails.data.accessToken
      )
    );
  }

  async function getAlTeams() {
    dispatch(getAllTeams(orgId, userInfoDetails.data.accessToken));
  }

  var path = location?.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;

  useEffect(() => {
    if (filterName) {
      let currentLocation = localStorage.getItem("currentLocation");
      let previousLocation = localStorage.getItem("previousLocation");
      if (currentLocation !== "/messages" && previousLocation !== "/messages") {
        localStorage.setItem("gotoChatkey", false);
      }
      localStorage.setItem("previousLocation", currentLocation);
      localStorage.setItem("currentLocation", location?.pathname);
    }
  }, [location?.pathname]);

  useEffect(() => {
    setInterval(() => {
      const userDetails = localStorage.getItem("userDetails");
      const userInfoDetails = JSON.parse(userDetails);
      const data = sendAccessToken(userInfoDetails);
      data?.then(async (res) => {
        userInfoDetails.data["accessToken"] = res.data.access;
        userInfoDetails.data["refreshToken"] = res.data.refresh;
        saveTokenInLocalStorage(userInfoDetails);
        dispatch(loginConfirmedAction(userInfoDetails));
      });
      data?.catch(async (err) => {
        if (err) {
          dispatch(logout(navigate));
        }
      });
    }, 60 * 10000);
  }, []);

  const handleFileUpload = async (e) => {
    // setShowTemplateLoader(true);
    var size = "";
    e.preventDefault();
    size = parseFloat(e.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
    size > 30 &&
      setFileErrorMessage(
        "Uploaded file too Big, please select a file less than 30mb"
      );

    const file = e.target.files[0];
    setFile(e.target.files[0]);
    if (file) {
      setSelectedFileName(file.name);
    }
    // if (size <= 30) {
    //   const data = uploadTaskAndGoalFile(
    //     e.target.files[0],
    //     userInfoDetails.data.accessToken
    //   );
    //   data?.then((res) => {
    //     if (res.data.message === "Tasks and goals are created successfully") {
    //       setShowTemplateLoader(false);
    //       setFileStatus(() => true);
    //       dispatch(readNotificationConfirmAction([]));
    //     }
    //     localStorage.setItem("timescope", localStorage.getItem("timescope"));
    //     dispatch(fileUploadConfirmAction(Math.floor(Math.random() * 100)));
    //     fileRef.current.value = "";
    //   });
    //   data?.catch((err) => {
    //     if (err.response.data.code === 400) {
    //       setShowTemplateLoader(false);
    //       setFileStatusErrorMessage(err.response.data.message);
    //       setFileStatusError(true);
    //     }
    //   });
    // }
  };

  const uploadHandler = (e) => {
    e.preventDefault();
    setUploadModalOpen(false);
    setShowTemplateLoader(true);
    const data = uploadTaskAndGoalFile(
      file,
      selectedScopeUpload,
      selectedTeamUpload,
      member,
      userInfoDetails.data.accessToken
    );
    data?.then((res) => {
      setShowTemplateLoader(false);
      if (res.data.message === "Tasks and goals are created successfully") {
        setShowTemplateLoader(false);
        setFileStatus(() => true);
        dispatch(readNotificationConfirmAction([]));
      }
      localStorage.setItem("timescope", localStorage.getItem("timescope"));
      dispatch(fileUploadConfirmAction(Math.floor(Math.random() * 100)));
      fileRef.current.value = "";

      // setSelectedScopeUpload("Organization")
    });
    data?.catch((err) => {
      setShowTemplateLoader(false);
      if (err.response.data.code === 400) {
        setShowTemplateLoader(false);
        setFileStatusErrorMessage(err.response.data.message);
        setFileStatusError(true);
      }
    });
  };

  useEffect(() => {
    if (searchModalOpen) setSearchKey(searchKey);
    scrollbar && searchKey
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [searchKey]);

  useEffect(() => {
    if (localStorage.getItem("subNotification") === "true") {
      setShowNotification(true);
    } else {
      setShowNotification(false);
    }
  }, [localStorage.getItem("subNotification"), showNotification]);

  useEffect(() => {
    // Check notification permission on component mount
    const checkNotificationPermission = async () => {
      console.log(Notification.permission !== 'granted', Notification.permission, "ASK PERMISSION")

      if (Notification.permission !== 'granted') {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted');
        } else {
          console.log('Notification permission denied');
        }
      }
    };

    checkNotificationPermission();
    connectToNotificationWebSocket();
  }, []);

  const connectToNotificationWebSocket = () => {
    const userData = JSON.parse(localStorage.getItem('userDetails'));
    const notificationUrl = `${notification_Url}${userData.data.id}`;
    console.log("Notification URL:", notificationUrl)
    const socket = new WebSocket(notificationUrl);

    socket.onopen = () => {
      console.log('Connected to notification WebSocket');
    };

    socket.onmessage = (event) => {
      console.log("Raw message received:", event.data); // Log raw message
      
      try {
        const msg = JSON.parse(event.data);
        console.log("Parsed notification message:", msg); // Log parsed message
        if (msg.type === 'notify') {
          handleNewMessageNotification(msg);
        } else {
          console.log("Message type is not 'notify':", msg.type);
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    };

    socket.onerror = (error) => {
      console.error('Notification WebSocket error:', error);
      // alert(`Notification WebSocket error: ${error}`);
    };

    socket.onclose = (event) => {
      console.log('Notification WebSocket closed:', event);
      // alert(`Notification WebSocket closed: ${event}`);
    };

    setNotificationSocket(socket);
  };

  const handleNewMessageNotification = (msg) => {
    const userData = JSON.parse(localStorage.getItem('userDetails'));

    if (Notification.permission === 'granted' && msg.sender_id !== userData.data.id) {
      const title = `New message from ${msg.sender_name}`;
      const body = msg.message;

      // Create an Audio object and play the notification sound
      const notificationSound = new Audio(MessageSeconNotification2);
      notificationSound.play();
      // alert(`Raw message received: ${body}`);
      
      new Notification(title, { body });
    } else {
      console.log("Notification permission not granted or sender is the user.");
    }
  };

  useEffect(() => {
    getPlanExpireNotificationDetails();
  }, []);

  async function getPlanExpireNotificationDetails() {
    const data = getPlanExpireNotification();
    data?.then((res) => {
      if (res?.data?.data.length)
        localStorage.setItem(
          "subNotification",
          res?.data?.data.length === 1 ? true : false
        );
      setShowNotification(true);
    });
    data?.catch((err) => { });
  }

  const toastSuccess = () => {
    FileStatus &&
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h4 style={{ fontSize: "18px" }}>
                Your template has been uploaded. You will see it as a new goal
                on the home page
                <Link
                  style={{
                    cursor: "pointer",
                    color: "red",
                    fontSize: "10px",
                  }}
                  to={"/home"}
                  className="badge badge-light"
                  onClick={() => setFileStatus(false)}
                >
                  <u> here </u>
                </Link>
                !
              </h4>
            </div>
          </div>
        </>,
        {
          toastId: "upload-template",
          className: "toast-success-container toast-success-container-after ",
        }
      );
  };

  const toastError = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i class="bi bi-x-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
          <div>
            <h2
              style={{
                fontSize: "22px",
              }}
            >
              Failed
            </h2>
            <h3
              style={{
                fontSize: "18px",
              }}
            >
              {FileStatusErrorMessage}
            </h3>
          </div>
        </div>
      </>,
      {
        toastId: "upload-template",
        className: "toast-error-container toast-error-container-after ",
      }
    );
  };

  return (
    <>
      {<RatingAndComment show={true}></RatingAndComment>}
      {FileStatus && toastSuccess()}
      {FileStatusError && toastError()}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={() => setFileStatus(false)}
        style={{ top: "5%", minWidth: "40%" }}
        toastStyle={
          FileStatus
            ? { backgroundColor: "#D4EBE2", content: "none" }
            : { backgroundColor: "#ed7e8b", content: "none" }
        }
        closeButton={true}
      />

      <Modal
        className="fade bd-example-modal-md mt-5 pt-5"
        show={trialModalOpen}
        size="md"
      >
        <Modal.Body>
          <div className="row">
            <h3>
              {!showTrialMessage
                ? "Your trial period has expired. Please buy a subscription plan!"
                : "Your licence has been expired, Please contact admin team"}
            </h3>
          </div>
          <div className="row mt-4">
            {/* <a href="/subscription-plan" className="figma-btn p-2 m-1 text-white float-end">Buy plan</a> */}
            {userInfoDetails?.data?.user_role === "owner" ? (
              <button
                variant=""
                className="figma-btn float-start align-center bg-blue m-1 p-2 "
                onClick={() => {
                  setTrialModalOpen(false);
                  setSubscriptionModalOpen(true);
                }}
              >
                Buy plan
              </button>
            ) : (
              ""
            )}
            <button
              variant=""
              className="figma-btn float-start align-center bg-primary m-1 p-2 "
              onClick={() => {
                window.alert("Not implemented!");
              }}
            >
              Contact support
            </button>
            <button
              variant=""
              className="figma-btn float-start align-center bg-danger m-1 p-2 "
              onClick={() => {
                localStorage.setItem("AutoLogin", false);
                dispatch(logout(navigate));
              }}
            >
              Logout
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="fade bd-example-modal-xl m-0 p-0"
        show={subscriptionModalOpen}
        size="xl"
      >
        <Modal.Body className="m-0 p-1">
          <div
            className="row m-0 p-3"
            style={{
              background: "#dddddd no-repeat center center",
              borderRadius: "10px",
            }}
          >
            <SubscriptionPlans />
            <div className="m-0 p-0 text-center">
              <button
                variant=""
                className="figma-btn bg-danger p-2"
                style={{ zIndex: "111", position: "relative" }}
                onClick={() => {

                  localStorage.setItem("AutoLogin", false);

                  setTimeout(() => {
                    dispatch(logout(navigate));
                  }, 1000);
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={customDateModal}
        size="lg"
      >
        <Modal.Body>
          <div className="text-center m-0">
            <button
              variant=""
              className="btn-close btn-danger float-end align-center m-1 p-2 "
              onClick={cancelCustom}
            ></button>
            <h2 className="text-center">Select start and end date</h2>
          </div>

          <div className="row">
            <div className="col-6 form-group mb-3">
              <h5>Start date</h5>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
              />
            </div>
            <div className="col-6 form-group mb-3">
              <h5>End date</h5>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
              />
            </div>
          </div>

          <div className="text-center mt-4">
            <button
              type="submit"
              className="figma-btn text-white float-right"
              onClick={() => {
                submitStartEndDate();
              }}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="fade bd-example-modal-md"
        show={openSendQuery}
        size="lg"
      >
        <Modal.Body>
          <div className="text-center m-0">
            <button
              variant=""
              className="btn-close btn-danger float-end align-center m-1 p-2 "
              onClick={CloseSendQueryModel}
            ></button>
            <h2 className="text-center">Add Suggestions</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="description"><h5>Description</h5></label>
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  placeholder="Enter description"
                  rows="4"
                  value={suggestionText}
                  onChange={(e) => setSuggestionText(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              type="submit"
              className="figma-btn text-white float-right"
              onClick={() => {
                submitSuggestion(suggestionText);
              }}
            >
              Submit &nbsp;
              {submittingSuggestion && (
                <span className="spinner-grow spinner-grow-sm"></span>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="fade bd-example-modal-xl m-0 p-0 "
        show={ShowTemplateLoader}
        size="md"
        centered
      >
        <Row className="m-2 bg-gray">
          <h2 className="text-center my-1">
            "Your template is being uploaded !"
          </h2>

          <div className="m-0 p-0 mt-3">
            <LoadingSpinner />
          </div>
        </Row>
      </Modal>

      <Modal
        className="custom-model"
        show={uploadModalOpen}
        style={{ maxWidth: "100%", margin: "auto 0" }}
        size="md"
      >
        <Modal.Body>
          <div className="mb-4">
            <button
              className="btn-close btn-danger float-end align-center m-1 p-2"
              onClick={() => {
                setUploadModalOpen(false);
                setIsDropdownReady(false);
              }}
            ></button>
            <h3 className="text-center mb-4">Upload strategy</h3>
          </div>

          <form onSubmit={uploadHandler}>
            <div
              className="d-flex"
            // style={{ display: "flex", flexDirection: "row" }}
            >
              <span>
                <h4>Select scope</h4>
              </span>

              <Dropdown className="mx-2" ref={target}>
                <Dropdown.Toggle
                  variant="outline-primary "
                  style={{ backgroundColor: "#ffffff !important" }}
                >
                  {selectedScopeUpload ? selectedScopeUpload : "Select Scope"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {scopeForUploadStrategy.map((scope, i) => (
                    <Dropdown.Item
                      onClick={() => {
                        storeScopeUpload(scope);
                        if (scope.name === "Team") {
                          setTeamListDropdownUpload(true);
                        } else {
                          setSelectedTeamUpload("");
                        }
                      }}
                      key={i}
                    >
                      {scope.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {showTeamDropdownUpload ? (
                <>
                  <Dropdown
                    className="mx-2"
                    autoFocus
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  // ref={tooltipRef}
                  >
                    <Dropdown.Toggle
                      variant="outline-primary"
                      style={{ backgroundColor: "#ffffff !important" }}
                      ref={dropdownToggleRef}
                    >
                      {selectedTeamUploadName && window.screen.width <= 374 ? (
                        `${selectedTeamUploadName.substring(0, 5)}...`
                      )
                        : (
                          selectedTeamUploadName && window.screen.width <= 768 ? `${selectedTeamUploadName.substring(0, 15)}...` : "Select Team")}

                    </Dropdown.Toggle>

                    <Dropdown.Menu onClick={() => { }}>
                      {teamList === undefined ? (
                        <Dropdown.Item>
                          <span style={{ color: "#6d6b6b" }}>
                            No teams available.
                          </span>{" "}
                          <br />
                          <hr className="my-3"></hr> Create team{" "}
                          <Link
                            style={{
                              cursor: "pointer",
                              color: "red",
                              fontSize: "10px",
                            }}
                            to={"/teams"}
                            state={"teams"}
                            className="badge badge-light"
                            onClick={() => {
                              setUploadModalOpen(false);
                            }}
                          >
                            <u>here </u>
                          </Link>
                        </Dropdown.Item>
                      ) : (
                        teamList &&
                        teamList.map((team, i) => (
                          <Dropdown.Item
                            // onClick={() => {
                            //   storeTeam(team);
                            // }}
                            onClick={() => {
                              storeTeamUpload(team);
                              if (team?.team_members) {
                                setTeamMemberListDropdown(true);
                                setMember("");
                              } else {
                                // setTooltip(false);
                                // setIsDropdownReady(false);
                                // setSelectedTeam("");
                              }
                            }}
                            key={i}
                          >
                            {team?.name && window.screen.width <= 374 ? (
                              `${team?.name.substring(0, 8)}...`
                            ) : (
                              team?.name && window.screen.width <= 1024 ? `${team?.name.substring(0, 15)}...` : team?.name
                            )}

                          </Dropdown.Item>
                        ))
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  {isDropdownReady && (
                    <CustomTooltip
                      show={
                        showTeamDropdownUpload &&
                        tooltip
                        &&
                        (selectedTeamUpload === undefined ||
                          selectedTeamUpload === null) &&
                        selectedScopeUpload === "Team"
                      }
                      content={
                        <>
                          <button
                            variant=""
                            className="float-end align-center m-1"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "medium",
                            }}
                            onClick={() => {
                              setTooltip(false);
                            }}
                          >
                            {" "}
                            x
                          </button>
                          <h5 className="p text-white">
                            Please select team or create team from{" "}
                            <Link
                              style={{
                                cursor: "pointer",
                                color: "red",
                                fontSize: "10px",
                              }}
                              to={"/teams"}
                              state={"teams"}
                              className="badge badge-light"
                              onClick={() => {
                                setIsDropdownReady(false);
                              }}
                            >
                              <u> here </u>
                            </Link>
                          </h5>
                        </>
                      }
                      targetRef={dropdownToggleRef} // Pass the ref to the tooltip
                    />
                  )}
                </>
              ) : (
                false
              )}

              {showTeamDropdownUpload && teamMemberListDropdown && (
                <>
                  <Dropdown className="mx-2" ref={target}>
                    <Dropdown.Toggle
                      variant="outline-primary "
                      style={{
                        backgroundColor: "#ffffff !important",
                      }}
                    >
                      {/* {selectedScope ? selectedScope : "Select Scope"} */}
                      {memberName ? memberName : "Select team member"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {teamMem?.map((member, i) => (
                        <Dropdown.Item
                          onClick={() => {
                            storeMember(member);
                          }}
                          key={i}
                        >
                          {member.first_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
            <div className="row mt-4 d-flex justify-content-center">
              <div className="" style={{ alignItems: "center" }}>
                <input
                  type="file"
                  ref={fileRef}
                  required
                  // style={{ display: "none" }}
                  onChange={(e) => {
                    handleFileUpload(e);
                  }}
                  placeholder="name"
                />
              </div>
              {/* {selectedFileName && <span>{selectedFileName}</span>} */}
            </div>
            <div className="row">
              <div className="mt-4" style={{ textAlign: "center" }}>
                <Button
                  className=" "
                  type="submit"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   fileRef.current.click();
                  //   setUploadModalOpen(false);
                  // }}
                  // onClick={uploadHandler}
                  style={{ backgroundColor: "#356fd4", color: "white" }}
                >
                  {/* <i class="bi bi-upload text-white"></i> */}
                  Upload
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {ShowGoalModal && (
        <GoalModal
          show={ShowGoalModal}
          goal={CreateTask}
          handle={handleClose}
        // Subgoal={{ createSubGoal, selectedGoal, EditTask }}
        ></GoalModal>
      )}

      {Shows && (
        <TaskModal
          show={Shows}
          goal={null}
          owner=""
          handle={setShows}
        // setIsTaskCreated={setIsTaskCreated}
        />
      )}

      <div className="header">
        <Row className="headerstartegy" style={{ backgroundColor: "white" }}>
          <h4 className="text-center my-1 text-white">
            " {orgDetails?.description && window.screen.width <= 768 ? `${orgDetails?.description?.substring(0, 20)}...` : `${orgDetails?.description?.substring(0, 60)}...`} "
          </h4>
        </Row>
        <div id="headerheight" className="header-content m-0 bg-white">
          <div className="row m-0 p-0">
            <nav className="navbar navbar-expand align-header mt-0 header-nav header-navber">
              <p className="d-flex justify-content-between m-0 p-0">
                {window.innerWidth <= 1024 ? (
                  <>
                    <img src={PlansomLogo2} className="Plansom-Logo1" title="Plansom-logo" alt="Plansom-logo" />
                  </>
                ) : (
                  toggleSideBar === true ? (
                    <>
                      <img src={PlansomLogo} className="Plansom-Logo " title="Plansom-logo" alt="Plansom-logo" onClick={() => {
                        navigate("/")
                      }} />
                      <p className="Plansom-Logos"></p>
                    </>
                  ) : (
                    <img src={PlansomLogo2} className="Plansom-Logo1" title="Plansom-logo" alt="Plansom-logo" onClick={() => {
                      navigate("/")
                    }} />
                  )

                )}
              </p>
              {window.innerWidth <= 1024 ? (
                toggleSideBar ? (
                  <i
                    className="bi bi-chevron-double-left h2 pt-2"
                    style={{ cursor: "pointer" }}
                    onClick={handle}
                  ></i>
                ) : (
                  <i
                    onClick={handle}
                    className="bi bi-chevron-double-right h2 pt-2"
                    style={{ cursor: "pointer" }}
                  ></i>
                )
              ) : (
                toggleSideBar ? (
                  <i
                    className="bi bi-list h2 pt-2 sidebar-icon"
                    style={{ cursor: "pointer", marginLeft: "20px", marginTop: "10px" }}
                    onClick={handle}
                  ></i>
                ) : (
                  <i
                    onClick={handle}
                    className="bi bi-chevron-double-right h2 pt-2"
                    style={{ cursor: "pointer" }}
                  ></i>
                )
              )}


              <div className="collapse navbar-collapse justify-content-between">
                <div className="header-left">
                  <div
                    className="nav-itemn"
                    style={{
                      textTransform: "capitalize",
                      marginBottom: "20px"
                      // display: window.innerWidth <= 550 ? "none" : "block",
                    }}
                  >
                    {/* {finalName.join(" ").length === 0
                      ? "Dashboard"
                      : finalName.join(" ") === "dashboard dark"
                        ? "Dashboard"
                        : finalName.join(" ") === "goals" ? "Goals" : finalName.join(" ")} */}
                  </div>
                  <Dropdown className="dropdown m-0 p-0 me-1 search-dropdown">
                    <Dropdown.Toggle className="storeHeaderDropDown" style={{ marginTop: "14px" }}>
                      ☰
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setModalClick(true); // Ensure modal is visible
                          setSearchModalOpen(true);
                        }}
                        className="p-0 m-0"
                      >
                        <Link className="dropdown-item" data-testid="search-item" to="#">
                          Search
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleDropdownFilterClick}
                        className="p-0 m-0"
                      >
                        <Link className="dropdown-item" data-testid="filter-item" to="#">
                          Filter
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          // e.preventDefault();
                          // fileRef.current.click();
                          setUploadModalOpen(true);
                          // setSelectedScopeUpload("");
                          setTeamListDropdownUpload(false);
                          setTeamMemberListDropdown(false);
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          className="dropdown-item"
                          data-testid="upload-csv"
                          to="#"
                        >
                          Upload csv
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div
                  className="p-0 m-0 react-search-box"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalClick(() => !modalClick);
                  }}
                  style={{ width: "35vw" }}
                >
                  <ReactSearchBox
                    placeholder="Search"
                    onFocus={(e) => {
                      e.preventDefault();
                      setSearchModalOpen(true);
                    }}
                    autoFocus={searchModalOpen && modalClick}
                    leftIcon={<i className="fa fa-search" />}
                    dropDownHoverColor="#f2f3f4"
                    inputBorderColor="#949596"
                    inputFontColor="#131f4c"
                  />
                </div>
                {searchModalOpen && modalClick && (
                  <SearchModal
                    ModalClick={setModalClick}
                    searchOpen={searchModalOpen}
                    setSearchOpen={setSearchModalOpen}
                  />
                )}
                <ul className="navbar-nav header-right float-end me-3">
                  <Dropdown
                    as="li"
                    className="nav-item dropdown header-profile mx-2 mt-2"
                  >
                    <Link to={""}>
                      <img
                        className="header-info rounded-circle mt-2"
                        src={profilePic || profilePicture}
                        width={16}
                        alt="app-profile"
                        title="app-profile"
                      ></img>
                    </Link>
                    <Dropdown.Toggle
                      id="hideemail"
                      variant=""
                      as="a"
                      className="nav-link i-false c-pointer mx-0"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <div className="header-info mx-2">
                        {!userInfoDetails?.data?.first_name ||
                          !userInfoDetails?.data?.last_name ? (
                          <small className="fs-12 font-w400">
                            {userInfoDetails?.data?.email}
                          </small>
                        ) : (
                          <Link to={""}>
                            <small className="fs-12 font-w400">
                              {userInfoDetails?.data?.first_name
                                // +
                                // " " +
                                // userInfoDetails?.data?.last_name
                              }
                            </small>
                          </Link>
                        )}
                      </div>
                    </Dropdown.Toggle>
                  </Dropdown>

                  <Dropdown className="dropdown m-0 p-0 me-1 mt-2 ">
                    <Dropdown.Toggle
                      as="button"
                      data-toggle=""
                      data-testid="header-menu"
                      variant="bg-white"
                      className="btn  b-0 fs-30"
                      style={{ color: "#000000" }}
                    ></Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          className="dropdown-item"
                          data-testid="Organization-item"
                          to="/organization-list"
                        >
                          Organizations
                        </Link>
                      </Dropdown.Item> */}

                      {/* <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          className="dropdown-item"
                          data-testid="Teams-item"
                          to="/teams"
                        >
                          Teams
                        </Link>
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          to="/app-profile"
                          data-testid="Profile-item"
                          className="dropdown-item ai-icon"
                        >
                          Profile
                        </Link>
                      </Dropdown.Item>
                      {userInfoDetails?.data?.user_role === "owner" && <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          className="dropdown-item"
                          data-testid="Users-item"
                          to="/invite-users"
                        >
                          Organization
                        </Link>
                      </Dropdown.Item>}

                      {/* <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          to="/coach-setting"
                          data-testid="coach-setting"
                          className="dropdown-item ai-icon"
                        >
                          Coach Setting
                        </Link>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          to="/email-notification"
                          data-testid="email-notification"
                          className="dropdown-item ai-icon"
                        >
                          Manage emails
                        </Link>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        {userInfoDetails?.data?.user_role === "owner" &&
                          userInfoDetails?.data?.org_access === true && (
                            <Link
                              to="/subscription-plan"
                              data-testid="Template-item"
                              className="dropdown-item ai-icon"
                            >
                              Subscription plans
                            </Link>

                          )}
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          to="/templates"
                          data-testid="Template-item"
                          className="dropdown-item ai-icon"
                        >
                          Templates
                        </Link>
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          to="/template"
                          data-testid="Template-item"
                          className="dropdown-item ai-icon"
                        >
                          Plan Templates
                        </Link>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item
                        onClick={() => {
                          "";
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          to="/chatbots"
                          data-testid="Template-item"
                          className="dropdown-item ai-icon"
                        >
                          Question Templates
                        </Link>
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          OpenSendQueryModel()
                        }}
                        className="p-0 m-0"
                      >
                        <Link
                          data-testid="Contactus-item"
                          className="dropdown-item ai-icon"
                        >
                          Contact us
                        </Link>
                      </Dropdown.Item>
                      <LogoutPage />
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </div>
            </nav>
          </div>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Filter </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ padding: "10px 0 10px 0" }}>
              <Row className="">
                <Col className="filter11 ">
                  <div className="float-end d-flex p-0">
                    <OverlayTrigger
                      delay={{ hide: 100, show: 10 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          <h5 className="p text-white">Please select organization</h5>
                        </Tooltip>
                      )}
                      placement="top"
                    >
                      <Dropdown
                        className="me-2"
                        style={{ backgroundColor: "#ffffff !important" }}
                      >
                        <Dropdown.Toggle
                          className="outline-primary org-name-filter"
                          style={{
                            backgroundColor: "#ffffff !important",
                            whiteSpace: "nowrap", // Prevent line breaks
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "200px", // Adjust this value based on your design
                          }}
                        >
                          {selectedItem
                            ? selectedItem.split(" ").slice(0, 10).join(" ")
                            : "Select Organization"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {userOrgDetails ? (
                            userOrgDetails.map((orgItem, i) => (
                              <Dropdown.Item
                                onClick={(e) => {
                                  e.preventDefault();
                                  storeOrg(orgItem);
                                }}
                                style={{ overflowWrap: "anywhere" }}
                                className="KPmm"
                                key={i}
                              >
                                {orgItem.organization_name} ({orgItem.role})
                              </Dropdown.Item>
                            ))
                          ) : (
                            <Dropdown.Item>Empty List</Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </OverlayTrigger>

                    <OverlayTrigger
                      delay={{ hide: 100, show: 10 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          <h5 className="p text-white"> Please select scope</h5>
                        </Tooltip>
                      )}
                      placement="top"
                    >
                      <Dropdown className="mx-2 " ref={target}>
                        <Dropdown.Toggle
                          variant="outline-primary "
                          style={{ backgroundColor: "#ffffff !important" }}
                        >
                          {selectedScope ? selectedScope : "Select Scope"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {scope.map((scope, i) => (
                            <Dropdown.Item
                              onClick={() => {
                                storeScope(scope);
                                if (scope.name === "Team") {
                                  setTooltip(() => true);

                                  setTeamListDropdown(true);
                                } else {
                                  setTooltip(false);
                                  setIsDropdownReady(false);
                                  setSelectedTeam("");
                                }
                              }}
                              key={i}
                            >
                              {scope.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </OverlayTrigger>

                    {showTeamDropdown ? (
                      <>
                        <Dropdown
                          className="mx-2"
                          autoFocus
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          ref={tooltipRef}
                        >
                          <Dropdown.Toggle
                            variant="outline-primary"
                            style={{ backgroundColor: "#ffffff !important" }}
                            ref={dropdownToggleRef}
                          >

                            {selectedTeam && window.screen.width <= 768 ? `${selectedTeam.substring(0, 8)}...` : "Select Team"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu onClick={() => { }}>
                            {teamList === undefined ? (
                              <Dropdown.Item>
                                <span style={{ color: "#6d6b6b" }}>
                                  No teams available.
                                </span>{" "}
                                <br />
                                <hr className="my-3"></hr> Create team{" "}
                                <Link
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    fontSize: "10px",
                                  }}
                                  to={"/teams"}
                                  state={"teams"}
                                  className="badge badge-light"
                                >
                                  <u>here </u>
                                </Link>
                              </Dropdown.Item>
                            ) : (
                              teamList &&
                              teamList?.map((team, i) => (
                                <Dropdown.Item
                                  onClick={() => {
                                    storeTeam(team);
                                  }}
                                  key={i}
                                >
                                  {team?.name && window.screen.width <= 768 ? `${team?.name.substring(0, 15)}...` : team?.name}
                                  { }
                                </Dropdown.Item>
                              ))
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                        {isDropdownReady && (
                          <CustomTooltip
                            show={
                              showTeamDropdown &&
                              tooltip &&
                              (selectedTeam === undefined ||
                                selectedTeam === null) &&
                              selectedScope === "Team"
                            }
                            content={
                              <>
                                <button
                                  variant=""
                                  className="float-end align-center m-1"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "medium",
                                  }}
                                  onClick={() => {
                                    setTooltip(false);
                                  }}
                                >
                                  {" "}
                                  x
                                </button>
                                <h5 className="p text-white">
                                  Please select team or create team from{" "}
                                  <Link
                                    style={{
                                      cursor: "pointer",
                                      color: "red",
                                      fontSize: "10px",
                                    }}
                                    to={"/teams"}
                                    state={"teams"}
                                    className="badge badge-light"
                                  >
                                    <u> here </u>
                                  </Link>
                                </h5>
                              </>
                            }
                            targetRef={dropdownToggleRef} // Pass the ref to the tooltip
                          />
                        )}
                      </>
                    ) : (
                      false
                    )}

                    <OverlayTrigger
                      delay={{ hide: 100, show: 10 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          <h5 className="p text-white">
                            {" "}
                            Please select time scope
                          </h5>
                        </Tooltip>
                      )}
                      placement="top"
                    >
                      <Dropdown className="mx-2 ">
                        <Dropdown.Toggle
                          variant="outline-primary "
                          style={{ backgroundColor: "#ffffff !important" }}
                        >
                          {selectedTimeScope
                            ? selectedTimeScope
                            : "Select Time Scope"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {timeScope.map((timescope, i) => (
                            <Dropdown.Item
                              onClick={() => {
                                storeTimeScope(timescope);
                              }}
                              key={i}
                            >
                              {timescope.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </OverlayTrigger>

                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          <Row className="headerdropdown" style={{ background: "#F3F4F6" }}>
            <Col className="filter11 p-0 m-0">
              <div className="float-end d-flex p-0">
                <button
                  className="submitButtonformikForm figma-btn text-white goal-save-button mx-3 p-0 m-0"
                  type="submit"
                  onClick={(event) => {
                    const targetElement = event.currentTarget;
                    setAnchorEl(anchorEl ? null : targetElement);
                    setShowPopOver(!showPopOver);
                  }}
                >
                  + Create
                </button>
                <Popover
                  open={showPopOver}
                  anchorEl={anchorEl}
                  disableScrollLock={true}
                  onClose={handleCreatePopOver}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div style={{
                    border: "1px solid #E7E9ED",
                    backgroundColor: "#ffffff",
                    padding: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    height: "80px",
                    display: "flex",
                    alignItems: "center"
                  }}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                      <button
                        className="submitButtonformikForm figma-btn text-white goal-save-button mx-3"
                        style={{ width: "87px" }}
                        onClick={() => {

                        }}
                      >
                        AI Plan
                      </button>
                      <button
                        className="submitButtonformikForm figma-btn text-white goal-save-button mx-3"
                        style={{ width: "87px" }}
                        onClick={(event) => {
                          handleCreatePopOver();
                          setTimeout(() => {
                            setShowGoalModal(true);
                            setCreateTask(true);
                          }, 100)
                        }}
                      >
                        Goal
                      </button>
                      <button
                        className="submitButtonformikForm figma-btn text-white goal-save-button mx-3"
                        style={{ width: "87px" }}
                        onClick={(event) => {
                          handleCreatePopOver();
                          setShows(true);
                        }}
                      >
                        Task
                      </button>
                    </Stack>
                  </div>
                </Popover>
                {/* <span className="filterText mx-4 my-1">Filter: </span> */}
                {/* <OverlayTrigger
                    delay={{ hide: 100, show: 10 }}
                    overlay={(props) => (
                      <></>
                      // <Tooltip {...props}>
                      //   <h5 className="p text-white">Please select organization</h5>
                      // </Tooltip>
                    )}
                    placement="top"
                  >
                    <Dropdown
                      className="me-2"
                      style={{ backgroundColor: "#ffffff !important" }}
                    >
                      <Dropdown.Toggle
                        className="outline-primary org-name-filter"
                        style={{
                          backgroundColor: "#ffffff !important",
                          whiteSpace: "nowrap", // Prevent line breaks
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px", // Adjust this value based on your design
                        }}
                      >
                        {selectedItem
                          ? selectedItem.split(" ").slice(0, 10).join(" ")
                          : "Select Organization"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {userOrgDetails ? (
                          userOrgDetails.map((orgItem, i) => (
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();
                                storeOrg(orgItem);
                              }}
                              style={{ overflowWrap: "anywhere" }}
                              className="KPmm"
                              key={i}
                            >
                              {orgItem.organization_name} ({orgItem.role})
                            </Dropdown.Item>
                          ))
                        ) : (
                          <></>
                          // <Dropdown.Item>Empty List</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </OverlayTrigger>

                <OverlayTrigger
                  delay={{ hide: 100, show: 10 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <h5 className="p text-white"> Please select scope</h5>
                    </Tooltip>
                  )}
                  placement="top"
                >
                  <Dropdown className="mx-2 " ref={target}>
                    <Dropdown.Toggle
                      variant="outline-primary "
                      style={{ backgroundColor: "#ffffff !important" }}
                    >
                      {selectedScope ? selectedScope : "Select Scope"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {scope.map((scope, i) => (
                        <Dropdown.Item
                          onClick={() => {
                            storeScope(scope);
                            if (scope.name === "Team") {
                              setTooltip(() => true);

                              setTeamListDropdown(true);
                            } else {
                              setTooltip(false);
                              setIsDropdownReady(false);
                              setSelectedTeam("");
                            }
                          }}
                          key={i}
                        >
                          {scope.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </OverlayTrigger>

                {showTeamDropdown ? (
                  <>
                    <Dropdown
                      className="mx-2"
                      autoFocus
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      ref={tooltipRef}
                    >
                      <Dropdown.Toggle
                        variant="outline-primary"
                        style={{ backgroundColor: "#ffffff !important" }}
                        ref={dropdownToggleRef}
                      >
                        {selectedTeam  ? `${selectedTeam.substring(0, 8)}...` : "Select Team"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu onClick={() => { }}>
                        {teamList === undefined ? (
                          <Dropdown.Item>
                            <span style={{ color: "#6d6b6b" }}>
                              No teams available.
                            </span>{" "}
                            <br />
                            <hr className="my-3"></hr> Create team{" "}
                            <Link
                              style={{
                                cursor: "pointer",
                                color: "red",
                                fontSize: "10px",
                              }}
                              to={"/teams"}
                              state={"teams"}
                              className="badge badge-light"
                            >
                              <u>here </u>
                            </Link>
                          </Dropdown.Item>
                        ) : (
                          teamList &&
                          teamList?.map((team, i) => (
                            <Dropdown.Item
                              onClick={() => {
                                storeTeam(team);
                              }}
                              key={i}
                            >
                            {team?.name && window.screen.width <=1024? `${team?.name.substring(0, 15)}...` : team?.name}
                            {}
                            </Dropdown.Item>
                          ))
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    {isDropdownReady && (
                      <CustomTooltip
                        show={
                          showTeamDropdown &&
                          tooltip &&
                          (selectedTeam === undefined ||
                            selectedTeam === null) &&
                          selectedScope === "Team"
                        }
                        content={
                          <>
                            <button
                              variant=""
                              className="float-end align-center m-1"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "medium",
                              }}
                              onClick={() => {
                                setTooltip(false);
                              }}
                            >
                              {" "}
                              x
                            </button>
                            <h5 className="p text-white">
                              Please select team or create team from{" "}
                              <Link
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                  fontSize: "10px",
                                }}
                                to={"/teams"}
                                state={"teams"}
                                className="badge badge-light"
                              >
                                <u> here </u>
                              </Link>
                            </h5>
                          </>
                        }
                        targetRef={dropdownToggleRef} // Pass the ref to the tooltip
                      />
                    )}
                  </>
                ) : (
                  false
                )}

                <OverlayTrigger
                  delay={{ hide: 100, show: 10 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <h5 className="p text-white">
                        {" "}
                        Please select time scope
                      </h5>
                    </Tooltip>
                  )}
                  placement="top"
                >
                  <Dropdown className="mx-2 ">
                    <Dropdown.Toggle
                      variant="outline-primary "
                      style={{ backgroundColor: "#ffffff !important" }}
                    >
                      {selectedTimeScope
                        ? selectedTimeScope
                        : "Select Time Scope"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {timeScope.map((timescope, i) => (
                        <Dropdown.Item
                          onClick={() => {
                            storeTimeScope(timescope);
                          }}
                          key={i}
                        >
                          {timescope.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </OverlayTrigger>

                <OverlayTrigger
                  delay={{ hide: 3000, show: 10 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <h5 className="p text-white file-import-tooltip">
                        {" "}
                        Upload a template. You can find a template from{" "}
                        <Link
                          style={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "10px",
                          }}
                          to={"/template"}
                          state={"template"}
                          className="badge badge-light"
                        >
                          <u> here </u>
                        </Link>
                      </h5>
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <Button
                    class="pt-2 mx-2 "
                    onClick={() => {
                      // e.preventDefault();
                      // fileRef.current.click();
                      setUploadModalOpen(true);
                      // setSelectedScopeUpload("");
                      setSelectedScopeUpload("Organization")
                      setTeamListDropdownUpload(false);
                      setTeamMemberListDropdown(false);
                    }}
                    style={{ backgroundColor: "#356fd4" }}
                  >
                    <i class="bi bi-upload text-white"></i>
                  </Button>
                </OverlayTrigger>
                <input
                  ref={fileRef}
                  onChange={(e) => {
                    handleFileUpload(e);
                  }}
                  multiple={false}
                  type="file"
                  accept=".csv,.xlsx,.xls"
                  hidden
                /> */}
              </div>
            </Col>
          </Row>
          {showNotification ? (
            <div
              style={{
                backgroundColor: "#f3f4f6",
                position: "relative",
                height: "100px",
              }}
            >
              <div
                className="subscription-plan-card m-3 p-2 float-end"
                style={{
                  width: "auto",
                  backgroundColor: "rgb(212, 235, 226)",
                  position: "absolute",
                  right: "2rem",
                  left: "20rem",
                }}
              >
                <div className="d-flex float-end p-2">
                  <div className="text-end">
                    <p className="p-0 m-0 text-end fs-18">
                      Your current subscription has been expired. Please check
                      your plan details and make payment to activate
                      subscription plan .
                    </p>
                  </div>
                  <div
                    className="text-end mx-3"
                    style={{ position: "relative", alignSelf: "center" }}
                    onClick={() => {
                      localStorage.setItem("subNotification", false);
                      setShowNotification(false);
                      navigate(location?.pathname);
                    }}
                  >
                    <i class="bi bi-x-circle fs-26 text-red m-2 pt-2"></i>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default Header;
